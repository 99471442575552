import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../../core/services/party.service';
import { Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { TransactionService } from '../../../../core/services/transaction.service';
import { EmployeeTransactionModel } from './employee-transactions.model'
import { DatePipe } from '@angular/common';
import { ASSET_HOST } from '../../../../config';

@Component({
  selector: 'app-employee-transactions',
  templateUrl: './employee-transactions.component.html',
  styleUrls: ['./employee-transactions.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EmployeeTransactionsComponent implements OnInit {

  id: any;
  employee: any = {};
  weeklyTransactions = [];
  monthlyTransactions = [];
  totalRecords: number;

  months = [{label: "Select", value: ""}, {label: "Jan", value: "Jan"}, {label: "Feb", value: "Feb"}, {label: "Mar", value: "Mar"}, {label: "Apr", value: "Apr"}, {label: "May", value: "May"}, {label: "Jun", value: "Jun"}, {label: "Jul", value: "Jul"}, {label: "Aug", value: "Aug"}, {label: "Sep", value: "Sep"}, {label: "Oct", value: "Oct"}, {label: "Nov", value: "Nov"}, {label: "Dec", value: "Dec"}];
  weeks = [{label: "Select", value: ""}, {label: "1st", value: "1st"}, {label: "2nd", value: "2nd"}, {label: "3rd", value: "3rd"}, {label: "4th", value: "4th"}];


  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  loading: boolean = true;
  img: string = "";

  constructor(private router: Router, private route: ActivatedRoute, private partyService: PartyService, private messageService: MessageService, private confirmationService: ConfirmationService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.employee = res;
      if(this.employee.logo == "") {
        this.img = "assets/img/user.png";
      }
      else {
        this.img = ASSET_HOST + this.employee.logo;
      }
    })

    this.getAllTransaction();
  }

  onNewWeekly() {
    let groupTransactionModel = new EmployeeTransactionModel('', '', this.formattedDate, 0, 0, '', true, '');
    this.weeklyTransactions = [...this.weeklyTransactions, groupTransactionModel];
  }

  onNewMonthly() {
    let groupTransactionModel = new EmployeeTransactionModel('', '', this.formattedDate, 0, 0, '', false, '');
    this.monthlyTransactions = [...this.monthlyTransactions, groupTransactionModel];
  }

  onGroupSave(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Added' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Transaction Failed' });
    }
  }

  onGroupUpdate(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Updated' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Update Transaction' });
    }
  }

  onUpdateWeekly(id, index) {
    this.loading = true;
    if(id) {
      let executionData = Object.assign({
        "month": this.weeklyTransactions[index].month,
        "payment_week": this.weeklyTransactions[index].paymentWeek,
        "date": this.weeklyTransactions[index].date,
        "amount": this.weeklyTransactions[index].amount,
        "dues": this.weeklyTransactions[index].dues,
        "notes": this.weeklyTransactions[index].notes
      });
      this.transactionService.updateTransaction(id, executionData).subscribe(res => {
        this.onGroupUpdate(true);
      });
    }
    else {
      let transactions: any = [];
      let executionData = Object.assign({
        "month": this.weeklyTransactions[index].month,
        "payment_week": this.weeklyTransactions[index].paymentWeek,
        "date": this.weeklyTransactions[index].date,
        "amount": this.weeklyTransactions[index].amount,
        "dues": this.weeklyTransactions[index].dues,
        "notes": this.weeklyTransactions[index].notes,
        "is_weekly": this.weeklyTransactions[index].isWeekly,
        "party_id": this.employee._id
      });
      transactions.push(executionData);
      this.transactionService.addTransaction(transactions).subscribe(res => {
        this.onGroupSave(true)
      })
    }
    
  }


  onUpdateMonthly(id, index) {
    this.loading = true;
    if(id) {
      let executionData = Object.assign({
        "month": this.monthlyTransactions[index].month,
        "date": this.monthlyTransactions[index].date,
        "amount": this.monthlyTransactions[index].amount,
        "dues": this.monthlyTransactions[index].dues,
        "notes": this.monthlyTransactions[index].notes
      });
      this.transactionService.updateTransaction(id, executionData).subscribe(res => {
        this.onGroupUpdate(true);
      });
    }
    else {
      let transactions: any = [];
      let executionData = Object.assign({
        "month": this.monthlyTransactions[index].month,
        "date": this.monthlyTransactions[index].date,
        "amount": this.monthlyTransactions[index].amount,
        "dues": this.monthlyTransactions[index].dues,
        "notes": this.monthlyTransactions[index].notes,
        "is_weekly": this.monthlyTransactions[index].isWeekly,
        "party_id": this.employee._id
      });
      transactions.push(executionData);
      this.transactionService.addTransaction(transactions).subscribe(res => {
        this.onGroupSave(true)
      })
    }
    
  }

  getAllTransaction() {
    this.loading = true;
    this.weeklyTransactions = [];
    this.monthlyTransactions = [];
    this.transactionService.allTransaction(this.id).subscribe(res => {
      res.forEach(item => {
        let groupTransactionModel = new EmployeeTransactionModel(item._id, item.month, item.date, item.amount, item.dues, item.notes, item.is_weekly, item.payment_week);
        if(item.is_weekly) {
          this.weeklyTransactions = [...this.weeklyTransactions, groupTransactionModel];
        } else {
          this.monthlyTransactions = [...this.monthlyTransactions, groupTransactionModel];
        }
      });
      this.loading = false;
    })
  }

  modelChangedWeekly($event, index) {
    this.weeklyTransactions[index].dues = this.employee["weekly_salary"] - this.weeklyTransactions[index].amount;
    
  }

  modelChangedMonthly($event, index) {
    this.monthlyTransactions[index].dues = this.employee["monthly_salary"] - this.monthlyTransactions[index].amount;
    
  }

}
