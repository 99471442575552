import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let decoded: any;
        let token: any = sessionStorage.getItem("auth_token");

        // if only token exist, we need to decode
        if(token != null) {
            decoded = jwt_decode(token);
        }

        // if token is expired redirect to login screen
        if (decoded && decoded.exp < Math.floor(Date.now() / 1000)) {
            this.router.navigate(['/login']);
        }

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${sessionStorage.getItem("auth_token")}`
            }
        });

        return next.handle(request);


    }
}