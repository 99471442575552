import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css'],
  providers: [MessageService]
})
export class ManageComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(''),
    super_admin: new FormControl(''),
    status: new FormControl('Active'),
  })

  status = [{label: "Active", value: "Active"}, {label: "InActive", value: "InActive"}];

  showError = {
    flag: false,
    message: ""
  }

  loading: boolean = false;

  constructor(private loginService: LoginService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    let payload = this.loginForm.value;
    this.showError = {
      flag: false,
      message: ""
    }
    
    if (payload.super_admin === 'gpsadmin') {
      this.loading = true;
      this.loginService.manage(payload).subscribe(res => {
        this.loading = false;
        if (res.success === true) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Status Updated!!' });
          sessionStorage.removeItem('auth_token');
          sessionStorage.removeItem('data');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000)
        }
        else if (res.success === false) {
          this.showError.flag = true;
          this.showError.message = res.message;
        }
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'You are not a Super Admin' });
    }
    
  }

}
