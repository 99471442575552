import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../core/services/party.service';
import { TransactionService } from '../../../core/services/transaction.service';
import { RegisterService } from '../../../core/services/register.service';
import { EmployeeModel } from './employee.model';
import { Router } from '@angular/router';
import { format } from '../../../config';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EmployeeComponent implements OnInit {

  constructor(private router: Router, private partyService: PartyService, private transactionService: TransactionService, private registerService: RegisterService, private messageService: MessageService, private confirmationService: ConfirmationService) {
    this.username = sessionStorage.getItem("username");
  }

  allEmployee: any;
  toggleEdit: boolean = false;
  toggleNew: boolean = false;
  loading: boolean;
  executionData: {};
  id: any;
  username: any;
  party_type: string = "employee";

  employeeModel = new EmployeeModel('', '', '', '', '', '', 0, 0, true, '');

  fileToUpload: File | null = null;

  ngOnInit(): void {
    this.getAllEmployee();
  }

  getAllEmployee() {
    this.loading = true;
    this.partyService.allParty(this.username, this.party_type).subscribe(res => {
      if (res) {
        this.loading = false;
        this.allEmployee = res;
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.message ? res.message : 'Failed to fetch data' });
      }
    })
  }

  onEdit(id) {
    this.id = id;
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.loading = false;
      this.toggleEdit = true;
      this.employeeModel = new EmployeeModel(res.employee_name, res.father_name, res.contact_number, res.address, res.qualification, res.post, res.weekly_salary, res.monthly_salary, res.is_weekly, res.logo);
      this.executionData = Object.assign(
        {
          ...this.executionData,
          "employee_name": res.employee_name,
          "father_name": res.father_name,
          "contact_number": res.contact_number,
          "address": res.address,
          "qualification": res.qualification,
          "post": res.post,
          "weekly_salary": parseFloat(res.weekly_salary),
          "monthly_salary": parseFloat(res.monthly_salary),
          "is_weekly":  res.is_weekly,
          "logo": res.logo,
        });
    })
  }

  onNew() {
    this.toggleNew = true;
    this.employeeModel = new EmployeeModel('', '', '', '', '', '', 0, 0, true, '');
    this.executionData = Object.assign(
      {
        ...this.executionData,
        "party_type": this.party_type,
        "parent_user": this.username,
        "employee_name": '',
        "father_name": '',
        "contact_number": '',
        "address": '',
        "qualification": '',
        "post": '',
        "weekly_salary": 0,
        "monthly_salary": 0,
        "is_weekly": true,
        "logo": '',
      });
  }

  onDelete(id) {
    this.id = id;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.partyService.deleteParty(this.id).subscribe(async res => {
          if (res.success === true) {

            await this.transactionService.deleteTransaction(this.id).subscribe();

            this.partyService.allParty(this.username, this.party_type).subscribe(res => {
              this.loading = false;
              this.allEmployee = res;
            })
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Deleted' });
          }
          else if (res.success === false) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Deletion Failed' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  onSelect(id) {
    this.router.navigate(['home/parties/employee/transaction', {id: id}])
  }

  handleEdit($event) {
    this.loading = true;
    this.partyService.updateParty(this.id, this.executionData).subscribe(res => {
      //this.toggleEdit = false;
      if (res.success === true) {
        this.handleRefresh();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Updated' });
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updating Employee Failed' });
      }
    });
  }

  handleNew($event) {
    this.loading = true;
    this.partyService.addParty(this.executionData).subscribe(res => {
      if (res.success === true) {
        this.handleRefresh();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Employee Added' });
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Employee Failed' });
      }
    });
  }

  handleRefresh() {
    this.toggleEdit = false;
    this.toggleNew = false;
    this.partyService.allParty(this.username, this.party_type).subscribe(res => {
      this.loading = false;
      this.allEmployee = res;
    })
  }

  handleCancel() {
    this.toggleEdit = false;
    this.toggleNew = false;
  }

  modelChanged($event) {
    this.executionData = Object.assign({
      ...this.executionData,
      "employee_name": this.employeeModel.employeeName,
      "father_name": this.employeeModel.fatherName,
      "contact_number": this.employeeModel.contactNumber,
      "address": this.employeeModel.address,
      "qualification": this.employeeModel.qualification,
      "post": this.employeeModel.post,
      "weekly_salary": this.employeeModel.weeklySalary,
      "monthly_salary": this.employeeModel.monthlySalary,
      "is_weekly": this.employeeModel.isWeekly,
      "logo": this.employeeModel.logo
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (format.includes(this.fileToUpload.type)) {
      this.loading = true;
      this.registerService.upload(this.fileToUpload).subscribe(res => {
        this.executionData = Object.assign({
          ...this.executionData,
          "logo": res.data.filename
        });
        
        this.loading = false;
      }, err => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Server Error' });
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'File Format not Supported' });
    }
  }

}
