import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROTOCOL, PAANDUKAAN_MS, ENDPOINTS } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  allTransaction(id): Observable<any> {
    let params = new HttpParams();
    params = params.set('party_id', id);
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["allTransaction"], {params: params}).pipe(map(res => res));
  }

  addTransaction(payload): Observable<any> {
    return this.http.post(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["addTransaction"], payload).pipe(map(res => res));
  }

  oneTransaction(id): Observable<any> {
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["oneTransaction"] + "/" + id).pipe(map(res => res));
  }

  updateTransaction(id, payload): Observable<any> {
    return this.http.post(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["updateTransaction"] + "/" + id, payload).pipe(map(res => res));
  }

  deleteTransaction(id): Observable<any> {
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["deleteTransaction"] + "/" + id).pipe(map(res => res));
  }

  getTransactionByDate(id, date) {
    let params = new HttpParams();
    params = params.set('party_id', id);
    params = params.set('date', date);
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["getTransactionByDate"], {params: params}).pipe(map(res => res));
  }

  getTransactionByIds(ids, date) {
    let params = new HttpParams();
    params = params.set('party_id', ids);
    params = params.set('date', date);
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["getTransactionByIds"], {params: params}).pipe(map(res => res));
  }
}
