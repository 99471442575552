import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RegisterService } from '../../core/services/register.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { format } from '../../config';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [MessageService]
})
export class RegisterComponent implements OnInit {

  registerForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    email: new FormControl(''),
    company_name: new FormControl(''),
    owner_name: new FormControl(''),
    phone: new FormControl(''),
    address: new FormControl(''),
    logo: new FormControl(''),
    super_admin: new FormControl(''),
  })

  fileToUpload: File | null = null;
  upload: any;

  loading: boolean = false;

  constructor(private registerService: RegisterService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.registerForm) {
      let payload = this.registerForm.value;
      if (this.upload && this.upload.data) {
        payload.logo = this.upload.data.filename;
      }

      if (payload.username == "" || payload.password == "" || payload.confirm_password == "" || payload.owner_name == "" || payload.email == "") {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please fill the details' });

      } else if (payload.super_admin === 'gpsadmin') {
        this.loading = true;
        this.registerService.register(payload).subscribe(res => {
          if (res.success === true) {
            this.loading = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User Created' });
          }
          else if (res.success === false) {
            this.loading = false;
            this.messageService.add({ severity: 'error', summary: 'Failed', detail: res.message });
          }
        })
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'You are not a Super Admin' });
      }
    }

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    if (format.includes(this.fileToUpload.type)) {
      this.loading = true;
      this.registerService.upload(this.fileToUpload).subscribe(res => {
        this.upload = res;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Server Error' });
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'File Format not Supported' });
    }
  }


}
