import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROTOCOL, AUTH_MS, ENDPOINTS } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  login(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["login"], payload).pipe(map(res => res));
  }

  forgotPassword(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["forgotPassword"], payload).pipe(map(res => res));
  }

  manage(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["manage"], payload).pipe(map(res => res));
  }

  getUser(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["getUser"], payload).pipe(map(res => res));
  }

  sendEmail(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["sendEmail"], payload).pipe(map(res => res));
  }
}
