import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../../core/services/party.service';
import { Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { TransactionService } from '../../../../core/services/transaction.service';
import { HelperGroupTransactionModel } from './helper-transaction.model'
import { DatePipe } from '@angular/common';
import { ASSET_HOST } from '../../../../config';

@Component({
  selector: 'app-helper-transactions',
  templateUrl: './helper-transactions.component.html',
  styleUrls: ['./helper-transactions.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HelperTransactionsComponent implements OnInit {

  id: any;
  helper: any = {};
  allTransactions = [];
  totalRecords: number;

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  toggleNew: boolean = false;
  loading: boolean = true;
  img: string = "";

  allTransactionQuantity: number = 0;
  allTransactionTotalAmount: number = 0;
  allTransactionPaid: number = 0;
  allTransactionDues: number = 0;
  

  constructor(private router: Router, private route: ActivatedRoute, private partyService: PartyService, private messageService: MessageService, private confirmationService: ConfirmationService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.helper = res;
      if(this.helper.logo == "") {
        this.img = "assets/img/user.png";
      }
      else {
        this.img = ASSET_HOST + this.helper.logo;
      }
    })

    this.getAllTransaction();
  }

  onNew() {
    let groupTransactionModel = new HelperGroupTransactionModel('', this.formattedDate, 0, '', 0, 0, 0, 0, 0, 0, 0, '');
    this.allTransactions = [...this.allTransactions, groupTransactionModel];
  }

  onGroupSave(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Added' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Transaction Failed' });
    }
  }

  onGroupUpdate(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Updated' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Update Transaction' });
    }
  }

  onUpdate(id, index) {
    this.loading = true;
    if(id) {
      let executionData = Object.assign({
        "quantity": this.allTransactions[index].quantity,
        "station": this.allTransactions[index].station,
        "bundel": this.allTransactions[index].bundel,
        "mashul": this.allTransactions[index].mashul,
        "loading_charge": this.allTransactions[index].loadingCharge,
        "transport_charge": this.allTransactions[index].transportCharge,
        "total_amount": this.allTransactions[index].totalAmount,
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "mode": this.allTransactions[index].mode
      });
      this.transactionService.updateTransaction(id, executionData).subscribe(res => {
        this.onGroupUpdate(true);
      });
    }
    else {
      let transactions: any = [];
      let executionData = Object.assign({
        "date": this.formattedDate,
        "quantity": this.allTransactions[index].quantity,
        "station": this.allTransactions[index].station,
        "bundel": this.allTransactions[index].bundel,
        "mashul": this.allTransactions[index].mashul,
        "loading_charge": this.allTransactions[index].loadingCharge,
        "transport_charge": this.allTransactions[index].transportCharge,
        "total_amount": this.allTransactions[index].totalAmount,
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "mode": this.allTransactions[index].mode,
        "party_id": this.helper._id
      });
      transactions.push(executionData);
      this.transactionService.addTransaction(transactions).subscribe(res => {
        this.onGroupSave(true)
      })
    }
    
  }

  getAllTransaction() {
    this.loading = true;
    this.transactionService.allTransaction(this.id).subscribe(res => {
      this.allTransactions = [];
      res.forEach(item => {
        let groupTransactionModel = new HelperGroupTransactionModel(item._id, item.date, item.quantity, item.station, item.bundel, item.mashul, item.loading_charge, item.transport_charge, item.total_amount, item.paid, item.dues, item.mode);
        this.allTransactions = [...this.allTransactions, groupTransactionModel];
      });
      this.loading = false;
      this.totalRecords=this.allTransactions.length;
      this.calculateAllTransactionTotal();
    })
  }

  modelChanged($event, index) {
    if(this.allTransactions[index].bundel > 0) {
      this.allTransactions[index].mashul = 0;
      this.allTransactions[index].loadingCharge = 0;
      this.allTransactions[index].totalAmount = (this.allTransactions[index].quantity * this.allTransactions[index].bundel) + parseFloat(this.allTransactions[index].transportCharge);
    }
    else if(this.allTransactions[index].mashul > 0) {
      this.allTransactions[index].bundel = 0
      this.allTransactions[index].totalAmount = (this.allTransactions[index].quantity * this.allTransactions[index].loadingCharge) + parseFloat(this.allTransactions[index].mashul) + parseFloat(this.allTransactions[index].transportCharge);
    }
    this.allTransactions[index].dues = this.allTransactions[index].totalAmount - this.allTransactions[index].paid
    
  }

  handleRefresh() {
    this.toggleNew = false;
  }

  calculateAllTransactionTotal() {
    let quantity: number = 0;
    let totalAmount: number = 0;
    let paid: number = 0;
    let dues: number = 0;
    this.allTransactions.forEach(item => {
      quantity += parseFloat(item.quantity);
      totalAmount += parseFloat(item.totalAmount);
      paid += parseFloat(item.paid);
      dues += parseFloat(item.dues);
    });

    this.allTransactionQuantity = quantity;
    this.allTransactionTotalAmount = totalAmount;
    this.allTransactionPaid = paid;
    this.allTransactionDues = dues;
  }

}
