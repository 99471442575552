import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROTOCOL, PAANDUKAAN_MS, ENDPOINTS } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  total(): Observable<any> {
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["total"]).pipe(map(res => res));
  }

  active(user): Observable<any> {
    let params = new HttpParams();
    params = params.set('user', user);
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["active"], {params: params}).pipe(map(res => res));
  }

}
