import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROTOCOL, PAANDUKAAN_MS, ENDPOINTS } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class PartyService {

  constructor(private http: HttpClient) { }

  allParty(parent_user, party_type): Observable<any> {
    let params = new HttpParams();
    params = params.set('parent_user', parent_user);
    params = params.set('party_type', party_type);
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["allParty"], {params: params}).pipe(map(res => res));
  }

  addParty(payload): Observable<any> {
    return this.http.post(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["addParty"], payload).pipe(map(res => res));
  }

  oneParty(id): Observable<any> {
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["oneParty"] + "/" + id).pipe(map(res => res));
  }

  updateParty(id, payload): Observable<any> {
    return this.http.post(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["updateParty"] + "/" + id, payload).pipe(map(res => res));
  }

  deleteParty(id): Observable<any> {
    return this.http.get(PROTOCOL + PAANDUKAAN_MS.IP + ":" + PAANDUKAAN_MS.PORT + ENDPOINTS["deleteParty"] + "/" + id).pipe(map(res => res));
  }
}
