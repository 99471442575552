export class EmployeeTransactionModel {
    constructor(
        public _id: string,
        public month: string,
        public date: string,
        public amount: number,
        public dues: number,
        public notes: string,
        public isWeekly: boolean,
        public paymentWeek?: string,
    ){}
}