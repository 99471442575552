export class HelperGroupTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public station: string,
        public bundel: number,
        public mashul: number,
        public loadingCharge: number,
        public transportCharge: number,
        public totalAmount: number,
        public paid: number,
        public dues: number,
        public mode: string
    ){}
}