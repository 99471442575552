export class OtherGroupTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public rate: number,
        public otherCharge: number,
        public damage: number,
        public totalAmount: number,
        public paid: number,
        public dues: number,
        public mode: string,
        public notes: string
    ){}
}