import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../../core/services/party.service';
import { Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { TransactionService } from '../../../../core/services/transaction.service';
import { OtherGroupTransactionModel } from './others-transaction.model'
import { DatePipe } from '@angular/common';
import { ASSET_HOST } from '../../../../config';

@Component({
  selector: 'app-others-transactions',
  templateUrl: './others-transactions.component.html',
  styleUrls: ['./others-transactions.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class OthersTransactionsComponent implements OnInit {

  id: any;
  other: any = {};
  allTransactions = [];
  totalRecords: number;

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  loading: boolean = true;
  img: string = "";

  allTransactionQuantity: number = 0;
  allTransactionTotalAmount: number = 0;
  allTransactionPaid: number = 0;
  allTransactionDues: number = 0;
  

  constructor(private router: Router, private route: ActivatedRoute, private partyService: PartyService, private messageService: MessageService, private confirmationService: ConfirmationService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.other = res;
      if(this.other.logo == "") {
        this.img = "assets/img/user.png";
      }
      else {
        this.img = ASSET_HOST + this.other.logo;
      }
    })

    this.getAllTransaction();
  }

  onNew() {
    let groupTransactionModel = new OtherGroupTransactionModel('', this.formattedDate, 0, 0, 0, 0, 0, 0, 0, '', '');
    this.allTransactions = [...this.allTransactions, groupTransactionModel];
  }

  onGroupSave(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Added' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Transaction Failed' });
    }
  }

  onGroupUpdate(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Updated' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Update Transaction' });
    }
  }

  onUpdate(id, index) {
    this.loading = true;
    if(id) {
      let executionData = Object.assign({
        "quantity": this.allTransactions[index].quantity,
        "rate": this.allTransactions[index].rate,
        "other_charge": this.allTransactions[index].otherCharge,
        "damage": this.allTransactions[index].damage,
        "total_amount": this.allTransactions[index].totalAmount,
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "mode": this.allTransactions[index].mode,
        "notes": this.allTransactions[index].notes
      });
      this.transactionService.updateTransaction(id, executionData).subscribe(res => {
        this.onGroupUpdate(true);
      });
    }
    else {
      let transactions: any = [];
      let executionData = Object.assign({
        "date": this.formattedDate,
        "quantity": this.allTransactions[index].quantity,
        "rate": this.allTransactions[index].rate,
        "other_charge": this.allTransactions[index].otherCharge,
        "damage": this.allTransactions[index].damage,
        "total_amount": this.allTransactions[index].totalAmount,
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "mode": this.allTransactions[index].mode,
        "notes": this.allTransactions[index].notes,
        "party_id": this.other._id
      });
      transactions.push(executionData);
      this.transactionService.addTransaction(transactions).subscribe(res => {
        this.onGroupSave(true)
      })
    }
    
  }

  getAllTransaction() {
    this.loading = true;
    this.transactionService.allTransaction(this.id).subscribe(res => {
      this.allTransactions = [];
      res.forEach(item => {
        let groupTransactionModel = new OtherGroupTransactionModel(item._id, item.date, item.quantity, item.rate, item.other_charge, item.damage, item.total_amount, item.paid, item.dues, item.mode, item.notes);
        this.allTransactions = [...this.allTransactions, groupTransactionModel];
      });
      this.loading = false;
      this.calculateAllTransactionTotal();
    })
  }

  modelChanged($event, index) {
    if (this.allTransactions[index].quantity > 0 || this.allTransactions[index].rate > 0) {
      this.allTransactions[index].totalAmount = (this.allTransactions[index].quantity * this.allTransactions[index].rate);
    } else {
      this.allTransactions[index].quantity = 0;
      this.allTransactions[index].rate = 0;
    }

    if(this.allTransactions[index].otherCharge != "") {
      this.allTransactions[index].totalAmount = this.allTransactions[index].totalAmount + parseFloat(this.allTransactions[index].otherCharge);
    } else {
      this.allTransactions[index].otherCharge = 0;
    }
    
    if(this.allTransactions[index].damage != "") {
      this.allTransactions[index].totalAmount = this.allTransactions[index].totalAmount - parseFloat(this.allTransactions[index].damage);
    } else {
      this.allTransactions[index].damage = 0;
    }
    
    this.allTransactions[index].dues = this.allTransactions[index].totalAmount - parseFloat(this.allTransactions[index].paid);
   
  }

  calculateAllTransactionTotal() {
    let quantity: number = 0;
    let totalAmount: number = 0;
    let paid: number = 0;
    let dues: number = 0;
    this.allTransactions.forEach(item => {
      quantity += parseFloat(item.quantity);
      totalAmount += parseFloat(item.totalAmount);
      paid += parseFloat(item.paid);
      dues += parseFloat(item.dues);
    });

    this.allTransactionQuantity = quantity;
    this.allTransactionTotalAmount = totalAmount;
    this.allTransactionPaid = paid;
    this.allTransactionDues = dues;
  }
}
