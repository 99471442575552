export class SupplierTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public rate: number,
        public amount: number,
        public commission: number,
        public commissionAmount: number,
        public damage: number,
        public damageAmount: number,
        public damageReputation: number,
        public extraBetel: number,
        public betelType: string,
        public totalAmount: number
    ){}

}

export class SupplierGroupTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public totalAmount: number,
        public paid: number,
        public dues: number,
        public acFrom: string,
        public acTo: string,
        public mode: string,
        public notes: string
    ){}
}