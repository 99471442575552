import { Component, OnInit } from '@angular/core';
import { APP_INFO } from '../../config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public company_name = APP_INFO.company_name;
  public year = APP_INFO.year;
  
  constructor() { }

  ngOnInit() {
  }

}
