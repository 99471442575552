import { Component, OnInit, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../core/services/party.service';
import { TransactionService } from '../../../core/services/transaction.service';
import { TransactionDaywiseService } from '../../../core/services/transaction-daywise.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AccountComponent implements OnInit {

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  onThisDate: Date = new Date();

  today: any;
  yesterday: any;

  allSupplier: any;
  allConsumer: any;
  allHelper: any;
  allOthers: any;
  allEmployee: any;

  id: any;
  groupTransactionId: any;
  username: any;
  loading: boolean;

  inBetelStock: any;
  inBetelToday: number = 0;
  inBetelExtra: number = 0;
  inBetelTotal: number = 0;
  inDamage: number = 0;
  inDamageReputation: number = 0;
  outBetelStock: any;
  outBetelTotal: number = 0;
  outBetelAmount: number = 0;
  outBetelPaid: number = 0;
  outBetelDue: number = 0;

  damagedStock: number = 0;
  betelStock: number = 0;

  purchase: any;
  purchaseAmount: number = 0;
  purchasePaid: number = 0;
  purchaseDue: number = 0;

  sell: any;
  sellAmount: number = 0;
  sellPaid: number = 0;
  sellDue: number = 0;

  expenseOnHelper: any;
  expenseOnHelperAmount: number = 0;
  expenseOnHelperPaid: number = 0;
  expenseOnHelperDue: number = 0;

  expenseOnOthers: any;
  expenseOnOthersAmount: number = 0;
  expenseOnOthersPaid: number = 0;
  expenseOnOthersDue: number = 0;

  expenseOnEmployee: any;
  expenseOnEmployeeAmount: number = 0;

  allDamagedStock: any;
  tillDateStock: number = 0;
  tillDateDues: number = 0;

  count = 0;

  profit: number = 0;
  loss: number = 0;

  myCounter = new EventEmitter();

  constructor(private partyService: PartyService, private transactionService: TransactionService, private transactionDaywiseService: TransactionDaywiseService) {
    this.username = sessionStorage.getItem("username");
    this.id = JSON.parse(sessionStorage.getItem("data"))["id"];
  }

  ngOnInit(): void {
    this.getTillDate();

    this.myCounter.subscribe((count: number) => {
      if (count == 8 && this.today != null) {
        this.inBetelTotal = (this.inBetelToday + this.inBetelExtra + this.inDamageReputation) - (this.inDamage);
        this.outBetelDue = this.outBetelAmount - this.outBetelPaid;
        this.purchaseDue = this.purchaseAmount - this.purchasePaid;
        this.sellDue = this.sellAmount - this.sellPaid;
        this.betelStock = this.inBetelTotal - (this.outBetelTotal + this.damagedStock);
        this.expenseOnHelperDue = this.expenseOnHelperAmount - this.expenseOnHelperPaid;
        this.expenseOnOthersDue = this.expenseOnOthersAmount - this.expenseOnOthersPaid;
        let balance = this.sellAmount - (this.purchaseAmount + this.expenseOnHelperAmount + this.expenseOnOthersAmount + this.expenseOnEmployeeAmount);
        if (balance > 0) {
          this.profit = balance;
        }
        else {
          this.loss = balance;
        }
        this.loading = false;
      }

      else if (count == 8 && this.today == null) {
        this.inBetelTotal = (this.inBetelToday + this.inBetelExtra + this.inDamageReputation) - (this.inDamage);
        this.outBetelDue = this.outBetelAmount - this.outBetelPaid;
        this.purchaseDue = this.purchaseAmount - this.purchasePaid;
        this.sellDue = this.sellAmount - this.sellPaid;
        this.tillDateStock = this.inBetelTotal - (this.outBetelTotal + this.damagedStock);
        this.expenseOnHelperDue = this.expenseOnHelperAmount - this.expenseOnHelperPaid;
        this.expenseOnOthersDue = this.expenseOnOthersAmount - this.expenseOnOthersPaid;
        this.tillDateDues = this.purchaseDue + this.expenseOnHelperDue + this.expenseOnOthersDue;
        this.loading = false;
      }
    })

    
  }

  refreshAccount() {
    this.onSelect()
  }

  getPrevDate() {
    let date = new Date();
    return date.setDate(this.onThisDate.getDate() - 1);
  }

  onSelect() {
    this.today = this.pipe.transform(this.onThisDate, 'd MMM, y');

    this.count = 0;
    this.profit = 0;
    this.loss = 0;
    this.loading = true;

    this.getAllSupplier();
    this.getAllConsumer();
    this.getDamageStock();
    this.getAllHelper();
    this.getAllOthersParty();
    this.getAllEmployee();
  }

  getAllSupplier() {
    this.partyService.allParty(this.username, 'supplier').subscribe(res => {
      if (res) {
        this.allSupplier = res;

        let supplierIds = [];
        this.allSupplier.forEach(supplier => {
          supplierIds.push(supplier._id.toString())
        })

        this.getAllSupplierStocksToday(supplierIds);
        this.getAllSupplierPurchaseToday(supplierIds);
      }
    })
  }


  getAllSupplierStocksToday(supplierIds) {
    this.transactionDaywiseService.getTransactionDaywiseByIds(JSON.stringify(supplierIds), this.today).subscribe(res => {

      this.inBetelStock = res;

      this.inDamage = 0;
      this.inDamageReputation = 0;
      this.inBetelToday = 0;
      this.inBetelExtra = 0;
      this.inBetelTotal = 0;
      this.inBetelStock.forEach(betel => {
        this.inBetelToday += parseFloat(betel.quantity || 0);
        this.inBetelExtra += parseFloat(betel.extra_betel || 0);
        this.inDamage += parseFloat(betel.damage || 0);
        this.inDamageReputation += parseFloat(betel.damage_reputation || 0);

      })
      this.myCounter.emit(++this.count);

    })
  }

  getAllSupplierPurchaseToday(supplierIds) {
    this.transactionService.getTransactionByIds(JSON.stringify(supplierIds), this.today).subscribe(res => {

      this.purchase = res;

      this.purchaseAmount = 0;
      this.purchasePaid = 0;
      this.purchaseDue = 0;
      this.purchase.forEach(transaction => {
        this.purchaseAmount += parseFloat(transaction.total_amount || 0);
        this.purchasePaid += parseFloat(transaction.paid || 0);
      })
      this.myCounter.emit(++this.count);

    })
  }

  getAllConsumer() {
    this.partyService.allParty(this.username, 'consumer').subscribe(res => {
      if (res) {
        this.allConsumer = res;

        let consumerIds = [];
        this.allConsumer.forEach(consumer => {
          consumerIds.push(consumer._id.toString())
        })

        this.getAllConsumerStocksToday(consumerIds);
        this.getAllConsumerSellToday(consumerIds)

      }
    })
  }

  getAllConsumerStocksToday(consumerIds) {
    this.transactionDaywiseService.getTransactionDaywiseByIds(JSON.stringify(consumerIds), this.today).subscribe(res => {

      this.outBetelStock = res;

      this.outBetelTotal = 0;
      this.betelStock = 0;
      this.outBetelStock.forEach(betel => {
        this.outBetelTotal += parseFloat(betel.quantity || 0);
      })
      this.myCounter.emit(++this.count);

    })
  }

  getAllConsumerSellToday(consumerIds) {
    this.transactionService.getTransactionByIds(JSON.stringify(consumerIds), this.today).subscribe(res => {

      this.sell = res;

      this.sellAmount = 0;
      this.sellPaid = 0;
      this.sellDue = 0;
      this.sell.forEach(transaction => {
        this.sellAmount += parseFloat(transaction.total_amount || 0);
        this.sellPaid += parseFloat(transaction.paid || 0);
      })
      this.myCounter.emit(++this.count);

    })
  }

  getAllHelper() {
    this.partyService.allParty(this.username, 'helper').subscribe(res => {
      if (res) {
        this.allHelper = res;

        let helperIds = [];
        this.allHelper.forEach(Helper => {
          helperIds.push(Helper._id.toString())
        })

        this.getAllHelperToday(helperIds);

      }
    })
  }

  getAllHelperToday(helperIds) {
    this.transactionService.getTransactionByIds(JSON.stringify(helperIds), this.today).subscribe(res => {

      this.expenseOnHelper = res;

      this.expenseOnHelperAmount = 0;
      this.expenseOnHelperPaid = 0;
      this.expenseOnHelperDue = 0;
      this.expenseOnHelper.forEach(transaction => {
        this.expenseOnHelperAmount += parseFloat(transaction.total_amount || 0);
        this.expenseOnHelperPaid += parseFloat(transaction.paid || 0);
      })
      this.myCounter.emit(++this.count);
    })

  }

  getAllOthersParty() {
    this.partyService.allParty(this.username, 'others').subscribe(res => {
      if (res) {
        this.allOthers = res;

        let othersIds = [];
        this.allOthers.forEach(Others => {
          othersIds.push(Others._id.toString())
        })

        this.getAllOthersToday(othersIds);

      }
    })
  }

  getAllOthersToday(othersIds) {
    this.transactionService.getTransactionByIds(JSON.stringify(othersIds), this.today).subscribe(res => {

      this.expenseOnOthers = res;

      this.expenseOnOthersAmount = 0;
      this.expenseOnOthersPaid = 0;
      this.expenseOnOthersDue = 0;
      this.expenseOnOthers.forEach(transaction => {
        this.expenseOnOthersAmount += parseFloat(transaction.total_amount || 0);
        this.expenseOnOthersPaid += parseFloat(transaction.paid || 0);
      })
      this.myCounter.emit(++this.count);

    })
  }

  getAllEmployee() {
    this.partyService.allParty(this.username, 'employee').subscribe(res => {
      if (res) {
        this.allEmployee = res;

        let employeeIds = [];
        this.allEmployee.forEach(Employee => {
          employeeIds.push(Employee._id.toString())
        })

        this.getAllEmployeeToday(employeeIds);

      }
    })
  }

  getAllEmployeeToday(employeeIds) {
    this.transactionService.getTransactionByIds(JSON.stringify(employeeIds), this.today).subscribe(res => {

      this.expenseOnEmployee = res;

      this.expenseOnEmployeeAmount = 0;
      this.expenseOnEmployee.forEach(transaction => {
        this.expenseOnEmployeeAmount += parseFloat(transaction.amount || 0);
      })
      this.myCounter.emit(++this.count);

    })
  }

  modelChanged() {
    this.damagedStock = parseFloat(this.damagedStock.toString()) || 0;
    this.betelStock = this.inBetelTotal - (this.outBetelTotal + this.damagedStock);
  }

  getDamageStock() {
    this.damagedStock = 0;
    if (this.today == null) {
      this.transactionService.allTransaction(this.id).subscribe(res => {

        this.allDamagedStock = res;

        this.damagedStock = 0;
        this.allDamagedStock.forEach(transaction => {
          this.damagedStock += parseFloat(transaction.damage_betel || 0);
        })

        this.myCounter.emit(++this.count);
      })

    } else {
      this.transactionService.getTransactionByDate(this.id, this.today).subscribe(res => {
        if (res) {
          
          this.groupTransactionId = res["_id"];
          this.damagedStock = res["damage_betel"];
        }
        this.myCounter.emit(++this.count);
      })
    }

  }

  onSave() {
    this.loading = true;
    this.today = this.pipe.transform(this.onThisDate, 'd MMM, y');
    this.transactionService.getTransactionByDate(this.id, this.today).subscribe(res => {
      if (res == null) {
        let transactions: any = [];
        let executionData = Object.assign(
          {
            "date": this.today,
            "damage_betel": parseFloat(this.damagedStock.toString()),
            "party_id": this.id
          });
        transactions.push(executionData);
        this.transactionService.addTransaction(transactions).subscribe(res => {
          this.loading = false;
        })

      } else {
        this.groupTransactionId = res["_id"];
        let executionData = Object.assign(
          {
            "damage_betel": this.damagedStock
          });
        this.transactionService.updateTransaction(this.groupTransactionId, executionData).subscribe(res => {
          this.loading = false;
        });
      }

    })
  }

  getTillDate() {
    this.today = null;

    this.count = 0;
    this.profit = 0;
    this.loss = 0;
    this.loading = true;

    this.getAllSupplier();
    this.getAllConsumer();
    this.getDamageStock();
    this.getAllHelper();
    this.getAllOthersParty();
    this.getAllEmployee();
  }

}
