import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common/login/login.component';
import { HomeComponent } from './site/home/home.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { RegisterComponent } from './common/register/register.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { SupplierComponent } from './site/interface/supplier/supplier.component';
import { ConsumerComponent } from './site/interface/consumer/consumer.component';
import { SupplierTransactionsComponent } from './site/interface/supplier/transactions/supplier-transactions.component';
import { SupplierTransactionsDaywiseComponent } from './site/interface/supplier/transactions-daywise/supplier-transactions-daywise.component';
import { ConsumerTransactionsComponent } from './site/interface/consumer/transactions/consumer-transactions.component';
import { ConsumerTransactionsDaywiseComponent } from './site/interface/consumer/transactions-daywise/consumer-transactions-daywise.component';
import { HelperComponent } from './site/interface/helper/helper.component';
import { OthersComponent } from './site/interface/others/others.component';
import { EmployeeComponent } from './site/interface/employee/employee.component';
import { HelperTransactionsComponent } from './site/interface/helper/transactions/helper-transactions.component';
import { OthersTransactionsComponent } from './site/interface/others/transactions/others-transactions.component';
import { EmployeeTransactionsComponent } from './site/interface/employee/transactions/employee-transactions.component';
import { AccountComponent } from './site/interface/account/account.component';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { ManageComponent } from './common/manage/manage.component';
import { UpdatePasswordComponent } from './common/update-password/update-password.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    NotFoundComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SupplierComponent,
    ConsumerComponent,
    SupplierTransactionsComponent,
    SupplierTransactionsDaywiseComponent,
    ConsumerTransactionsComponent,
    ConsumerTransactionsDaywiseComponent,
    HelperComponent,
    OthersComponent,
    EmployeeComponent,
    HelperTransactionsComponent,
    OthersTransactionsComponent,
    EmployeeTransactionsComponent,
    AccountComponent,
    SpinnerComponent,
    ManageComponent,
    UpdatePasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    AutoCompleteModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
    ButtonModule,
    ConfirmDialogModule,
    TooltipModule,
    CalendarModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
