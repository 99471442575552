import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../core/services/party.service';
import { TransactionService } from '../../../core/services/transaction.service';
import { RegisterService } from '../../../core/services/register.service';
import { HelperModel } from './helper.model';
import { Router } from '@angular/router';
import { format } from '../../../config';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HelperComponent implements OnInit {

  constructor(private router: Router, private partyService: PartyService, private transactionService: TransactionService, private registerService: RegisterService, private messageService: MessageService, private confirmationService: ConfirmationService) {
    this.username = sessionStorage.getItem("username");
  }

  allHelper: any;
  toggleEdit: boolean = false;
  toggleNew: boolean = false;
  loading: boolean;
  executionData: {};
  id: any;
  username: any;
  party_type: string = "helper";

  helperModel = new HelperModel('', '', '', '', '', '', '');

  fileToUpload: File | null = null;

  ngOnInit(): void {
    this.getAllHelper();
  }

  getAllHelper() {
    this.loading = true;
    this.partyService.allParty(this.username, this.party_type).subscribe(res => {
      if (res) {
        this.loading = false;
        this.allHelper = res;
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.message ? res.message : 'Failed to fetch data' });
      }
    })
  }

  onEdit(id) {
    this.id = id;
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.loading = false;
      this.toggleEdit = true;
      this.helperModel = new HelperModel(res.company_name, res.owner_name, res.contact_number, res.address, res.account_detail, res.remark, res.logo);
      this.executionData = Object.assign(
        {
          ...this.executionData,
          "company_name": res.company_name,
          "owner_name": res.owner_name,
          "contact_number": res.contact_number,
          "address": res.address,
          "account_detail": res.account_detail,
          "remark": res.remark,
          "logo": res.logo,
        });
    })
  }

  onNew() {
    this.toggleNew = true;
    this.helperModel = new HelperModel('', '', '', '', '', '', '');
    this.executionData = Object.assign(
      {
        ...this.executionData,
        "party_type": this.party_type,
        "parent_user": this.username,
        "company_name": '',
        "owner_name": '',
        "contact_number": '',
        "address": '',
        "account_detail": '',
        "remark": '',
        "logo": '',
      });
  }

  onDelete(id) {
    this.id = id;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.partyService.deleteParty(this.id).subscribe(async res => {
          if (res.success === true) {

            await this.transactionService.deleteTransaction(this.id).subscribe();

            this.partyService.allParty(this.username, this.party_type).subscribe(res => {
              this.loading = false;
              this.allHelper = res;
            })
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Helper Deleted' });
          }
          else if (res.success === false) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Deletion Failed' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  onSelect(id) {
    this.router.navigate(['home/parties/helper/transaction', {id: id}])
  }

  handleEdit($event) {
    this.loading = true;
    this.partyService.updateParty(this.id, this.executionData).subscribe(res => {
      //this.toggleEdit = false;
      if (res.success === true) {
        this.handleRefresh();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Helper Updated' });
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updating Helper Failed' });
      }
    });
  }

  handleNew($event) {
    this.loading = true;
    this.partyService.addParty(this.executionData).subscribe(res => {
      if (res.success === true) {
        this.handleRefresh();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Helper Added' });
      }
      else if (res.success === false) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Helper Failed' });
      }
    });
  }

  handleRefresh() {
    this.toggleEdit = false;
    this.toggleNew = false;
    this.partyService.allParty(this.username, this.party_type).subscribe(res => {
      this.allHelper = res;
      this.loading = false;
    })
  }

  handleCancel() {
    this.toggleEdit = false;
    this.toggleNew = false;
  }

  modelChanged($event) {
    this.executionData = Object.assign({
      ...this.executionData,
      "company_name": this.helperModel.companyName,
      "owner_name": this.helperModel.ownerName,
      "contact_number": this.helperModel.contactNumber,
      "address": this.helperModel.address,
      "account_detail": this.helperModel.accountDetail,
      "remark": this.helperModel.remark,
      "logo": this.helperModel.logo
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (format.includes(this.fileToUpload.type)) {
      this.loading = true;
      this.registerService.upload(this.fileToUpload).subscribe(res => {
        this.executionData = Object.assign({
          ...this.executionData,
          "logo": res.data.filename
        });
        
        this.loading = false;
      }, err => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Server Error' });
      })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'File Format not Supported' });
    }
  }

}
