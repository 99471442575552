export class EmployeeModel {
    constructor(
        public employeeName: string,
        public fatherName: string,
        public contactNumber: string,
        public address: string,
        public qualification: string,
        public post: string,
        public weeklySalary: number,
        public monthlySalary: number,
        public isWeekly: boolean,
        public logo?: string
    ){}

}