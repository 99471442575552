import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PartyService } from '../../../../core/services/party.service';
import { Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { TransactionService } from '../../../../core/services/transaction.service';
import { ConsumerGroupTransactionModel } from './consumer-transaction.model';
import { ASSET_HOST } from '../../../../config';

@Component({
  selector: 'app-consumer-transactions',
  templateUrl: './consumer-transactions.component.html',
  styleUrls: ['./consumer-transactions.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ConsumerTransactionsComponent implements OnInit {

  id: any;
  consumer: any = {};
  action: string = "";
  transactionDate: string;
  previousDate: string;
  sumPreviousDue = 0;
  allTransactions = [];

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  toggleNew: boolean = false;
  toggleEdit: boolean = false;
  togglePay: boolean = true;
  loading: boolean;
  img: string = "";

  allTransactionQuantity: number = 0;
  allTransactionTotalAmount: number = 0;
  allTransactionPaid: number = 0;
  allTransactionDues: number = 0;

  constructor(private router: Router, private route: ActivatedRoute, private partyService: PartyService, private messageService: MessageService, private confirmationService: ConfirmationService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.partyService.oneParty(this.id).subscribe(res => {
      this.consumer = res;
      if(this.consumer.logo == "") {
        this.img = "assets/img/user.png";
      }
      else {
        this.img = ASSET_HOST + this.consumer.logo;
      }
    })

    this.getAllTransaction();
  }

  onNew() {
    this.action = "new";
    this.toggleNew = !this.toggleNew;
  }

  onEdit(id?, date?, index?) {
    this.action = "edit";
    this.transactionDate = date;
    if(index) {
      this.previousDate = this.allTransactions[index -1].date;
      for( let i = 0; i <= index - 1; i++ ) {
        this.sumPreviousDue = this.sumPreviousDue + this.allTransactions[i].dues;
      }
    }
    else {
      this.previousDate = '';
      this.sumPreviousDue = 0;
    }
    this.toggleEdit = !this.toggleEdit;
  }

  onPay() {
    this.togglePay = false;
    let found  = this.allTransactions.find( item => item.date == this.formattedDate);
    if(found === undefined) {
      let groupTransactionModel = new ConsumerGroupTransactionModel('', this.formattedDate, 0, 0, 0, 0, 0, '', '', '', '');
      this.allTransactions = [...this.allTransactions, groupTransactionModel];
    } else {
      alert('Transaction already exist, edit the same day entry to update.')
    }
    
  }

  onGroupSave(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Added' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Adding New Transaction Failed' });
    }
  }

  onGroupUpdate(toggle) {
    if (toggle == true) {
      this.getAllTransaction();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Transaction Updated' });
    }
    else if (toggle == false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Update Transaction' });
    }
  }

  onUpdate(id, index) {
    this.loading = true;
    if (id) {
      let executionData = Object.assign({
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "ac_from": this.allTransactions[index].acFrom,
        "ac_to": this.allTransactions[index].acTo,
        "mode": this.allTransactions[index].mode,
        "notes": this.allTransactions[index].notes
      });
      this.transactionService.updateTransaction(id, executionData).subscribe(res => {
        this.onGroupUpdate(true);
      });
    }
    else {
      let transactions: any = [];
      let executionData = Object.assign({
        "date": this.formattedDate,
        "quantity": this.allTransactions[index].quantity,
        "total_amount": this.allTransactions[index].totalAmount,
        "paid": this.allTransactions[index].paid,
        "dues": this.allTransactions[index].dues,
        "ac_from": this.allTransactions[index].acFrom,
        "ac_to": this.allTransactions[index].acTo,
        "mode": this.allTransactions[index].mode,
        "notes": this.allTransactions[index].notes,
        "party_id": this.consumer._id
      });
      transactions.push(executionData);
      this.transactionService.addTransaction(transactions).subscribe(res => {
        this.onGroupSave(true)
      })
    }
  }

  getAllTransaction() {
    this.loading = true;
    this.transactionService.allTransaction(this.id).subscribe(res => {
      this.allTransactions = [];
      res.forEach(item => {
        let groupTransactionModel = new ConsumerGroupTransactionModel(item._id, item.date, item.quantity, item.total_amount, item.paid, item.dues, item.damage_discount, item.ac_from, item.ac_to, item.mode, item.notes);
        this.allTransactions = [...this.allTransactions, groupTransactionModel];
      });
      this.loading = false;
      this.calculateAllTransactionTotal();
    })
  }

  modelChanged($event, index) {
    this.allTransactions[index].dues = this.allTransactions[index].totalAmount - this.allTransactions[index].paid - this.allTransactions[index].damage_discount;
    this.calculateAllTransactionTotal();
  }

  handleRefresh() {
    this.toggleEdit = false;
    this.toggleNew = false;
  }

  calculateAllTransactionTotal() {
    let quantity: number = 0;
    let totalAmount: number = 0;
    let paid: number = 0;
    let dues: number = 0;
    this.allTransactions.forEach(item => {
      quantity += parseFloat(item.quantity);
      totalAmount += parseFloat(item.totalAmount);
      paid += parseFloat(item.paid);
      dues += parseFloat(item.dues);
    });

    this.allTransactionQuantity = quantity;
    this.allTransactionTotalAmount = totalAmount;
    this.allTransactionPaid = paid;
    this.allTransactionDues = dues;
  }

}
