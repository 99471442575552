import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './site/home/home.component';
import { LoginComponent } from './common/login/login.component';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { RegisterComponent } from './common/register/register.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { SupplierComponent } from './site/interface/supplier/supplier.component';
import { ConsumerComponent } from './site/interface/consumer/consumer.component';
import { HelperComponent } from './site/interface/helper/helper.component';
import { OthersComponent } from './site/interface/others/others.component';
import { EmployeeComponent } from './site/interface/employee/employee.component';
import { SupplierTransactionsComponent } from './site/interface/supplier/transactions/supplier-transactions.component';
import { ConsumerTransactionsComponent } from './site/interface/consumer/transactions/consumer-transactions.component';
import { EmployeeTransactionsComponent } from './site/interface/employee/transactions/employee-transactions.component';
import { HelperTransactionsComponent } from './site/interface/helper/transactions/helper-transactions.component';
import { OthersTransactionsComponent } from './site/interface/others/transactions/others-transactions.component';
import { AccountComponent } from './site/interface/account/account.component';
import { ManageComponent } from './common/manage/manage.component';
import { UpdatePasswordComponent } from './common/update-password/update-password.component';



const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'parties',
        children: [
          { path: '', redirectTo: 'supplier', pathMatch: 'full' },
          {
            path: 'supplier',
            children: [
              { path: '', component: SupplierComponent },
              { path: 'transaction', component: SupplierTransactionsComponent },
            ]
          },
          {
            path: 'consumer',
            children: [
              { path: '', component: ConsumerComponent },
              { path: 'transaction', component: ConsumerTransactionsComponent },
            ] 
          },
          {
            path: 'helper',
            children: [
              { path: '', component: HelperComponent },
              { path: 'transaction', component: HelperTransactionsComponent }
            ] 
          },
          {
            path: 'others',
            children: [
              { path: '', component: OthersComponent },
              { path: 'transaction', component: OthersTransactionsComponent }
            ] 
          },
          {
            path: 'employee',
            children: [
              { path: '', component: EmployeeComponent },
              { path: 'transaction', component: EmployeeTransactionsComponent }
            ] 
          },
        ]
      },
      { path: 'account', component: AccountComponent },
    ]
  },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'update-password/:token', component: UpdatePasswordComponent },
  { path: 'manage', component: ManageComponent },
  { path: '**', redirectTo: '/not-found' },
  { path: 'not-found', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
