import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css'],
  providers: [MessageService]
})
export class UpdatePasswordComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  })

  showError = {
    flag: false,
    message: ""
  }

  loading: boolean = false;

  constructor(private loginService: LoginService, private messageService: MessageService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    try {
      let decoded: any;
      let token: any = this.route.snapshot.paramMap.get('token');

      // if only token exist, we need to decode
      if (token != null) {
        decoded = jwt_decode(token);
      }

      // if token is expired redirect to login screen
      if (decoded && decoded.exp < Math.floor(Date.now() / 1000)) {
        this.showError.flag = true;
        this.showError.message = "Link expired...Redirecting!!";
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000)

      }
    } catch (e) {
      this.showError.flag = true;
      this.showError.message = "Invalid Token...Redirecting!!";
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000)
    }

  }

  onSubmit() {
    let payload = this.loginForm.value;
    this.showError = {
      flag: false,
      message: ""
    }
    this.loading = true;
    this.loginService.forgotPassword(payload).subscribe(res => {
      this.loading = false;
      if (res.success === true) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password Updated!!' });
        sessionStorage.removeItem('auth_token');
        sessionStorage.removeItem('data');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000)
      }
      else if (res.success === false) {
        this.showError.flag = true;
        this.showError.message = res.message;
      }
    })
  }

}
