export class ConsumerTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public rate: number,
        public chadan: number,
        public commission: number,
        public betelType: string,
        public totalAmount: number
    ){}

}

export class ConsumerGroupTransactionModel {
    constructor(
        public _id: string,
        public date: string,
        public quantity: number,
        public totalAmount: number,
        public paid: number,
        public dues: number,
        public damage_discount: number,
        public acFrom: string,
        public acTo: string,
        public mode: string,
        public notes: string
    ){}
}

export class ConsumerPaymentModel {
    constructor(
        public creditPaymentMode: any,
        public creditDepositToUs: any,
        public creditPreviousDeposit: any,
        public creditGrandTotal: any,
        public creditTotalExpense: any,
        public creditDamageDiscount: any,
        public debitPackingCharge: any,
        public debitExtraCharge: any,
        public debitTransportCharge: any,
        public debitTotalExpense: any,
        public debitPreviousBalance: any,
        public debitGrandTotal: any,
        public debitTotalDeposit: any,
        public debitDueToUs: any

    ){}
}