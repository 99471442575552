import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ASSET_HOST } from '../../config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  searchForm = new FormGroup({
    query: new FormControl('') 
  });

  username: any = "";
  data: any = {};

  img: string = "";

  constructor(private router: Router) { }

  ngOnInit() {
    this.username = sessionStorage.getItem("username");
    this.data = JSON.parse(sessionStorage.getItem("data"));
    if(this.data.logo == "") {
      this.img = "assets/img/user.png";
    }
    else {
      this.img = ASSET_HOST + this.data.logo;
    }
  }

  logout() {
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('data');
    this.router.navigate(['/login']);
  }

}
