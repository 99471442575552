export const AUTH_MS = {
    //IP: "localhost",
    IP: "77.68.123.9",
    PORT: "8001"
}

export const UPLOAD_MS = {
    //IP: "localhost",
    IP: "77.68.123.9",
    PORT: "8002"
}

export const PAANDUKAAN_MS = {
    //IP: "localhost",
    IP: "77.68.123.9",
    PORT: "8003"
}

export const APP_INFO = {
    company_name: "PaanerKhata.com",
    version: "1.0",
    year: "2021",
    address: "Bangalore, IN 560106"
}

export const format = ["image/png", "image/jpeg", "image/pjpeg", "image/bmp"];

export const PROTOCOL = "http://";

//export const ASSET_HOST = "http://3.108.215.93:8080/uploads/";
export const ASSET_HOST = "http://77.68.123.9:8080/uploads/";

export const ENDPOINTS = {
    "login": "/api/auth0/v1/login",
    "register": "/api/auth0/v1/register",
    "forgotPassword": "/api/auth0/v1/forgotPassword",
    "getUser": "/api/auth0/v1/getUser",
    "sendEmail": "/api/auth0/v1/sendEmail",
    "manage": "/api/auth0/v1/manage",
    "upload": "/api/uploadify/v1/single",
    "addParty": "/api/pandukaan-ms/v1/party/add",
    "allParty": "/api/pandukaan-ms/v1/party/all",
    "oneParty": "/api/pandukaan-ms/v1/party/one",
    "updateParty": "/api/pandukaan-ms/v1/party/update",
    "deleteParty": "/api/pandukaan-ms/v1/party/delete",
    "addTransaction": "/api/pandukaan-ms/v1/transaction/add",
    "allTransaction": "/api/pandukaan-ms/v1/transaction/all",
    "oneTransaction": "/api/pandukaan-ms/v1/transaction/one",
    "updateTransaction": "/api/pandukaan-ms/v1/transaction/update",
    "deleteTransaction": "/api/pandukaan-ms/v1/transaction/delete",
    "getTransactionByDate": "/api/pandukaan-ms/v1/transaction/getTransactionByDate",
    "getTransactionByIds": "/api/pandukaan-ms/v1/transaction/getTransactionByIds",
    "addTransactionDaywise": "/api/pandukaan-ms/v1/transaction/daywise/add",
    "allTransactionDaywise": "/api/pandukaan-ms/v1/transaction/daywise/all",
    "oneTransactionDaywise": "/api/pandukaan-ms/v1/transaction/daywise/one",
    "updateTransactionDaywise": "/api/pandukaan-ms/v1/transaction/daywise/update",
    "deleteTransactionDaywise": "/api/pandukaan-ms/v1/transaction/daywise/delete",
    "getTransactionDaywiseByDate": "/api/pandukaan-ms/v1/transaction/daywise/getTransactionByDate",
    "getTransactionDaywiseByIds": "/api/pandukaan-ms/v1/transaction/daywise/getTransactionByIds",
    "total": "/api/pandukaan-ms/v1/stats/total",
    "active": "/api/pandukaan-ms/v1/stats/active",
}