import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ConsumerTransactionModel } from '../transactions/consumer-transaction.model';
import { ConsumerPaymentModel } from '../transactions/consumer-transaction.model';
import { TransactionService } from '../../../../core/services/transaction.service';
import { TransactionDaywiseService } from '../../../../core/services/transaction-daywise.service';

@Component({
  selector: 'app-consumer-transactions-daywise',
  templateUrl: './consumer-transactions-daywise.component.html',
  styleUrls: ['./consumer-transactions-daywise.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ConsumerTransactionsDaywiseComponent implements OnInit {

  @Input() consumer: any;
  @Input() action: string;
  @Input() transactionDate: string;
  @Input() previousDate: string;
  @Input() sumPreviousDue: number;
  @Output() toggleNew = new EventEmitter();
  @Output() toggleEdit = new EventEmitter();
  @Output() saveGroupTransaction = new EventEmitter();
  @Output() updateGroupTransaction = new EventEmitter();

  daywiseTransaction = [];

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');
  yesterday = new Date();

  data: any;
  displayDate: any;
  thisDayQuantity: number = 0;
  thisDayTotalAmount: number = 0;
  groupTransactionId: any;
  loading: boolean;

  paymentModel = new ConsumerPaymentModel(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  extraPayment: number;


  constructor(private transactionDaywiseService: TransactionDaywiseService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem("data"));
    if (this.action == "new") {
      let transactionModel = new ConsumerTransactionModel('', '', 0, 0, 0, 0, '', 0);
      this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
      this.displayDate = this.formattedDate;
    }
    else if (this.action == "edit") {
      this.loading = true;
      this.displayDate = this.transactionDate;
      this.transactionDaywiseService.getTransactionDaywiseByDate(this.consumer._id, this.transactionDate).subscribe(res => {
        res.forEach(item => {
          let betelType = item.betel_type.toUpperCase();
          let transactionModel = new ConsumerTransactionModel(item._id, item.date, item.quantity, item.rate, item.chadan, item.commission, betelType, item.total_amount);
          this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
        });
        this.calculateThisDayTotal();
        this.transactionService.getTransactionByDate(this.consumer._id, this.displayDate).subscribe(res => {
          this.groupTransactionId = res["_id"];
          this.paymentModel = new ConsumerPaymentModel(res["ac_from"], res["paid"], 0, 0, 0, res["damage_discount"], res["packing_charge"], res["extra_charge"], res["transport_charge"], res["total_amount"], 0, 0, res["paid"], 0);
          this.paymentModel.debitPreviousBalance = this.sumPreviousDue;
          this.paymentChanged(null);
          this.loading = false;
        });
        /* this.yesterday.setDate(this.yesterday.getDate() - 1);
        console.log(this.pipe.transform(this.yesterday, 'd MMM, y'));
        if (this.previousDate != '') {
          this.transactionService.getTransactionByDate(this.consumer._id, this.previousDate).subscribe(res => {
            if (res) {
              this.loading = false;
              this.paymentModel.debitPreviousBalance = res["dues"] || 0;
              this.paymentChanged(null);
            }
          });
        } */
      })
    }

  }

  onBack() {
    this.toggleNew.emit();
    this.toggleEdit.emit();
  }

  onNew() {
    let transactionModel = new ConsumerTransactionModel('', '', 0, 0, 0, 0, '', 0);
    this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
  }

  onClear() {
    this.daywiseTransaction = [];
  }

  onSave() {
    let transactions: any = [];
    this.daywiseTransaction.forEach(item => {
      if (item.date !== "") {
        let executionData = Object.assign(
          {
            "date": item.date,
            "quantity": parseFloat(item.quantity),
            "rate": parseFloat(item.rate),
            "chadan": parseFloat(item.chadan),
            "commission": parseFloat(item.commission),
            "betel_type": item.betelType,
            "total_amount": item.totalAmount,
            "party_id": this.consumer._id
          });
        transactions.push(executionData);
      }
    });
    if (transactions.length != 0) {
      this.loading = true;
      this.transactionDaywiseService.addTransactionDaywise(transactions).subscribe(res => {
        if (res.success === true) {
          this.transactionService.getTransactionByDate(this.consumer._id, this.displayDate).subscribe(res => {
            if (res == null) {
              this.addGroupTransaction();
            }
            else if (res && res["length"] != 0) {
              this.groupTransactionId = res["_id"];
              let executionData = Object.assign(
                {
                  "quantity": this.thisDayQuantity + res["quantity"],
                  "total_amount": this.thisDayTotalAmount + res["total_amount"],
                  "dues": res["total_amount"] + this.thisDayTotalAmount - res["paid"]
                });
              this.editGroupTransaction(this.groupTransactionId, executionData);
            }
          })
        }
        else if (res.success === false) {
          this.loading = false;
          this.saveGroupTransaction.emit(false);
        }
      });
    }
  }

  onUpdate(index) {
    if (this.daywiseTransaction[index].date !== "") {
      let executionData = Object.assign(
        {
          "date": this.daywiseTransaction[index].date,
          "quantity": parseFloat(this.daywiseTransaction[index].quantity),
          "rate": parseFloat(this.daywiseTransaction[index].rate),
          "chadan": parseFloat(this.daywiseTransaction[index].chadan),
          "commission": parseFloat(this.daywiseTransaction[index].commission),
          "betel_type": this.daywiseTransaction[index].betelType,
          "total_amount": this.daywiseTransaction[index].totalAmount,
          "party_id": this.consumer._id
        });
      this.loading = true;
      this.transactionDaywiseService.updateTransactionDaywise(this.daywiseTransaction[index]._id, executionData).subscribe(res => {
        if (res.success === true) {
          this.transactionService.getTransactionByDate(this.consumer._id, this.displayDate).subscribe(res => {
            this.groupTransactionId = res["_id"];
            let executionData = Object.assign(
              {
                "quantity": this.thisDayQuantity,
                "total_amount": this.thisDayTotalAmount,
                "dues": this.thisDayTotalAmount - res["paid"]
              });
            this.editGroupTransaction(this.groupTransactionId, executionData);
          })
        }
        else if (res.success === false) {
          this.loading = false;
          this.saveGroupTransaction.emit(false);
        }
      });
    }
  }

  onDelete(index) {
    this.loading = true;
    this.transactionDaywiseService.deleteTransactionDaywise(this.daywiseTransaction[index]._id).subscribe(res => {
      if (res.success === true) {
        this.transactionService.getTransactionByDate(this.consumer._id, this.displayDate).subscribe(res => {
          this.groupTransactionId = res["_id"];
          let executionData = Object.assign(
            {
              "quantity": this.thisDayQuantity - parseFloat(this.daywiseTransaction[index].quantity),
              "total_amount": this.thisDayTotalAmount - this.daywiseTransaction[index].totalAmount,
            });
          this.editGroupTransaction(this.groupTransactionId, executionData);
        })
      }
      else if (res.success === false) {
        this.loading = false;
        this.saveGroupTransaction.emit(false);
      }
    });
  }

  addGroupTransaction() {
    let transactions: any = [];
    let executionData = Object.assign(
      {
        "date": this.displayDate,
        "quantity": this.thisDayQuantity,
        "total_amount": this.thisDayTotalAmount,
        "paid": 0,
        "dues": this.thisDayTotalAmount,
        "ac_from": "",
        "ac_to": "",
        "mode": "",
        "notes": "",
        "party_id": this.consumer._id
      });
    transactions.push(executionData);
    this.transactionService.addTransaction(transactions).subscribe(res => {
      this.loading = false;
      this.toggleNew.emit();
      this.saveGroupTransaction.emit(true);
    })
  }

  editGroupTransaction(id, payload) {
    this.transactionService.updateTransaction(id, payload).subscribe(res => {
      this.loading = false;
      this.toggleEdit.emit();
      this.updateGroupTransaction.emit(true);
    });
  }

  modelChanged($event, index) {
    this.daywiseTransaction[index].date = this.displayDate;
    this.daywiseTransaction[index].betelType = this.daywiseTransaction[index].betelType.toUpperCase();
    this.daywiseTransaction[index].totalAmount = (parseFloat(this.daywiseTransaction[index].rate) + parseFloat(this.daywiseTransaction[index].chadan) + parseFloat(this.daywiseTransaction[index].commission)) * this.daywiseTransaction[index].quantity / 10000;
    this.calculateThisDayTotal();
  }

  paymentChanged($event) {
    this.extraPayment = parseFloat(this.paymentModel.debitPackingCharge || 0) + parseFloat(this.paymentModel.debitExtraCharge || 0) + parseFloat(this.paymentModel.debitTransportCharge || 0);
    this.paymentModel.debitTotalExpense = this.thisDayTotalAmount + this.extraPayment;
    this.paymentModel.debitGrandTotal = parseFloat(this.paymentModel.debitPreviousBalance || 0) + parseFloat(this.paymentModel.debitTotalExpense || 0);
    this.paymentModel.debitDueToUs = parseFloat(this.paymentModel.debitGrandTotal || 0) - parseFloat(this.paymentModel.debitTotalDeposit || 0);
    this.paymentModel.debitDueToUs = this.paymentModel.debitDueToUs - parseFloat(this.paymentModel.creditDamageDiscount || 0);
  }

  calculateThisDayTotal() {
    let quantity: number = 0;
    let totalAmount: number = 0;
    this.daywiseTransaction.forEach(item => {
      quantity += parseFloat(item.quantity);
      totalAmount += parseFloat(item.totalAmount);
    });

    this.thisDayQuantity = quantity;
    this.thisDayTotalAmount = totalAmount;
  }

  handleUpdatePayment() {
    this.loading = true;
    this.transactionService.getTransactionByDate(this.consumer._id, this.displayDate).subscribe(res => {
      if (res == null) {
        this.loading = false;
      }
      else {
        this.groupTransactionId = res["_id"];
        let executionData = Object.assign(
          {
            "packing_charge": parseFloat(this.paymentModel.debitPackingCharge.toString()),
            "extra_charge": parseFloat(this.paymentModel.debitExtraCharge.toString()),
            "transport_charge": parseFloat(this.paymentModel.debitTransportCharge.toString()),
            "total_amount": this.thisDayTotalAmount + this.extraPayment,
            "damage_discount": parseFloat(this.paymentModel.creditDamageDiscount.toString()),
            "dues": (this.thisDayTotalAmount + this.extraPayment) - res["paid"] - parseFloat(this.paymentModel.creditDamageDiscount.toString())
          });
        this.editGroupTransaction(this.groupTransactionId, executionData);
      }
    })
  }

  onPrint() {
    const printContent = document.getElementById("printTable");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

}
