import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  })

  showError = {
    flag: false,
    message: ""
  }

  loading: boolean = false;

  constructor(private loginService: LoginService, private messageService: MessageService, private router: Router) { }

  ngOnInit() {
  }

  onSubmit() {
    let payload = this.loginForm.value;
    this.showError = {
      flag: false,
      message: ""
    }
    this.loading = true;
    this.loginService.login(payload).subscribe(res => {
      this.loading = false;
      if(res.success === true) {
        if(res.data.status === 'InActive') {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Your Subscription is Expired. Please contact administrator.' });
          sessionStorage.removeItem('auth_token');
          sessionStorage.removeItem('data');
          this.router.navigate(['/login']);
        }
        else if(res.data.status === 'Active') {
          sessionStorage.setItem("auth_token", res.token);
          sessionStorage.setItem("data", JSON.stringify(res.data));
          this.router.navigate(['/home']);
        }
      }
      else if(res.success === false) {
        this.showError.flag = true;
        this.showError.message = res.message;
      }
    })
  } 
}
