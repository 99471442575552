import { Component, OnInit } from '@angular/core';

import { DashboardService } from './dashboard.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private DashboardService: DashboardService) { }

  username: any = "";
  totalListing: any;
  activeListing: any;
  ngOnInit() {
    this.username = sessionStorage.getItem("username");
    this.DashboardService.active(this.username).subscribe(res => {
      this.activeListing = res;
    })
  }

}
