import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PROTOCOL, UPLOAD_MS, AUTH_MS, ENDPOINTS } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) {
  }

  register(payload : any): Observable<any> {
    return this.http.post(PROTOCOL + AUTH_MS.IP + ":" + AUTH_MS.PORT + ENDPOINTS["register"], payload).pipe(map(res => res));
  }

  upload(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("single", fileToUpload, fileToUpload.name);
    return this.http.post(PROTOCOL + UPLOAD_MS.IP + ":" + UPLOAD_MS.PORT + ENDPOINTS["upload"], formData).pipe(map(res => res));
  }

  
}
