export class SupplierModel {
    constructor(
        public companyName: string,
        public ownerName: string,
        public contactNumber: string,
        public address: string,
        public accountDetail: string,
        public remark: string,
        public logo?: string
    ){}

}