import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SupplierTransactionModel } from '../transactions/supplier-transaction.model';
import { TransactionService } from '../../../../core/services/transaction.service';
import { TransactionDaywiseService } from '../../../../core/services/transaction-daywise.service';

@Component({
  selector: 'app-supplier-transactions-daywise',
  templateUrl: './supplier-transactions-daywise.component.html',
  styleUrls: ['./supplier-transactions-daywise.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class SupplierTransactionsDaywiseComponent implements OnInit {

  @Input() supplier: any;
  @Input() action: string;
  @Input() transactionDate: string;
  @Output() toggleNew = new EventEmitter();
  @Output() toggleEdit = new EventEmitter();
  @Output() saveGroupTransaction = new EventEmitter();
  @Output() updateGroupTransaction = new EventEmitter();

  daywiseTransaction = [];

  now = Date.now();
  pipe = new DatePipe('en-US');
  formattedDate = this.pipe.transform(this.now, 'd MMM, y');

  displayDate: any;
  thisDayQuantity = 0;
  thisDayDamage = 0;
  thisDayExtraBetel = 0;
  thisDayDamageRep = 0;
  thisDayTotalAmount = 0;
  thisDayCommission = 0;
  groupTransactionId: any;
  loading: boolean;

  constructor(private transactionDaywiseService: TransactionDaywiseService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    if (this.action == "new") {
      let transactionModel = new SupplierTransactionModel('', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, '', 0);
      this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
      this.displayDate = this.formattedDate;
    }
    else if (this.action == "edit") {
      this.loading = true;
      this.displayDate = this.transactionDate;
      this.transactionDaywiseService.getTransactionDaywiseByDate(this.supplier._id, this.transactionDate).subscribe(res => {
        this.loading = false;
        res.forEach(item => {
          let amount = (item.rate * item.quantity) / 10000;
          let commissionAmount = (item.commission * amount) / 100;
          let damageAmount = (item.rate / 10000) * item.damage;
          let betelType = item.betel_type.toUpperCase();
          let transactionModel = new SupplierTransactionModel(item._id, item.date, item.quantity, item.rate, amount, item.commission, commissionAmount, item.damage, damageAmount, item.damage_reputation, item.extra_betel, betelType, item.total_amount);
          this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
        });
        this.calculateThisDayTotal();
      })

    }

  }

  onBack() {
    this.toggleNew.emit();
    this.toggleEdit.emit();
  }

  onNew() {
    let transactionModel = new SupplierTransactionModel('', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, '', 0);
    this.daywiseTransaction = [...this.daywiseTransaction, transactionModel];
  }

  onClear() {
    this.daywiseTransaction = [];
  }

  onSave() {
    let transactions: any = [];
    this.daywiseTransaction.forEach(item => {
      if (item.date !== "" && parseFloat(item.quantity) != 0) {
        let executionData = Object.assign(
          {
            "date": item.date,
            "quantity": parseFloat(item.quantity),
            "rate": parseFloat(item.rate),
            "commission": parseFloat(item.commission),
            "damage": parseFloat(item.damage),
            "damage_reputation": parseFloat(item.damageReputation),
            "extra_betel": parseFloat(item.extraBetel),
            "betel_type": item.betelType,
            "total_amount": item.totalAmount,
            "party_id": this.supplier._id
          });
        transactions.push(executionData);
      }
    });
    if (transactions.length != 0) {
      this.loading = true;
      this.transactionDaywiseService.addTransactionDaywise(transactions).subscribe(res => {
        if (res.success === true) {
          this.transactionService.getTransactionByDate(this.supplier._id, this.displayDate).subscribe(res => {
            if (res == null) {
              this.addGroupTransaction();
            }
            else if (res && res["length"] != 0) {
              this.groupTransactionId = res["_id"];
              let executionData = Object.assign(
                {
                  "quantity": this.thisDayQuantity + res["quantity"],
                  "total_amount": this.thisDayTotalAmount + res["total_amount"],
                  "dues": res["total_amount"] + this.thisDayTotalAmount - res["paid"]
                });
              this.editGroupTransaction(this.groupTransactionId, executionData);
            }
          })
        }
        else if (res.success === false) {
          this.loading = false;
          this.saveGroupTransaction.emit(false);
        }
      });
    }
  }

  onUpdate(index) {
    if (this.daywiseTransaction[index].date !== "") {
      let executionData = Object.assign(
        {
          "date": this.daywiseTransaction[index].date,
          "quantity": parseFloat(this.daywiseTransaction[index].quantity),
          "rate": parseFloat(this.daywiseTransaction[index].rate),
          "commission": parseFloat(this.daywiseTransaction[index].commission),
          "damage": parseFloat(this.daywiseTransaction[index].damage),
          "damage_reputation": parseFloat(this.daywiseTransaction[index].damageReputation),
          "extra_betel": parseFloat(this.daywiseTransaction[index].extraBetel),
          "betel_type": this.daywiseTransaction[index].betelType,
          "total_amount": this.daywiseTransaction[index].totalAmount,
          "party_id": this.supplier._id
        });
        this.loading = true;
      this.transactionDaywiseService.updateTransactionDaywise(this.daywiseTransaction[index]._id, executionData).subscribe(res => {
        if (res.success === true) {
          this.transactionService.getTransactionByDate(this.supplier._id, this.displayDate).subscribe(res => {
            this.groupTransactionId = res["_id"];
            let executionData = Object.assign(
              {
                "quantity": this.thisDayQuantity,
                "total_amount": this.thisDayTotalAmount,
                "dues": this.thisDayTotalAmount - res["paid"]
              });
            this.editGroupTransaction(this.groupTransactionId, executionData);
          })
        }
        else if (res.success === false) {
          this.loading = false;
          this.saveGroupTransaction.emit(false);
        }
      });
    }
  }

  onDelete(index) {
    this.loading = true;
    this.transactionDaywiseService.deleteTransactionDaywise(this.daywiseTransaction[index]._id).subscribe(res => {
      if (res.success === true) {
        this.transactionService.getTransactionByDate(this.supplier._id, this.displayDate).subscribe(res => {
          this.groupTransactionId = res["_id"];
          let executionData = Object.assign(
            {
              "quantity": this.thisDayQuantity - parseFloat(this.daywiseTransaction[index].quantity),
              "total_amount": this.thisDayTotalAmount - this.daywiseTransaction[index].totalAmount,
            });
          this.editGroupTransaction(this.groupTransactionId, executionData);
        })
      }
      else if (res.success === false) {
        this.loading = false;
        this.saveGroupTransaction.emit(false);
      }
    });
  }

  addGroupTransaction() {
    let transactions: any = [];
    let executionData = Object.assign(
      {
        "date": this.displayDate,
        "quantity": this.thisDayQuantity,
        "total_amount": this.thisDayTotalAmount,
        "paid": 0,
        "dues": this.thisDayTotalAmount,
        "ac_from": "",
        "ac_to": "",
        "mode": "",
        "notes": "",
        "party_id": this.supplier._id
      });
    transactions.push(executionData);
    this.transactionService.addTransaction(transactions).subscribe(res => {
      this.loading = false;
      this.toggleNew.emit();
      this.saveGroupTransaction.emit(true);
    })
  }

  editGroupTransaction(id, payload) {
    this.transactionService.updateTransaction(id, payload).subscribe(res => {
      this.loading = false;
      this.toggleEdit.emit();
      this.updateGroupTransaction.emit(true);
    });
  }

  modelChanged($event, index) {
    this.daywiseTransaction[index].date = this.displayDate;
    this.daywiseTransaction[index].amount = (this.daywiseTransaction[index].rate * this.daywiseTransaction[index].quantity) / 10000;
    this.daywiseTransaction[index].commissionAmount = (this.daywiseTransaction[index].commission * this.daywiseTransaction[index].amount) / 100;
    this.daywiseTransaction[index].damageAmount = (this.daywiseTransaction[index].rate / 10000) * this.daywiseTransaction[index].damage;
    this.daywiseTransaction[index].betelType = this.daywiseTransaction[index].betelType.toUpperCase();
    this.daywiseTransaction[index].totalAmount = this.daywiseTransaction[index].amount - (this.daywiseTransaction[index].commissionAmount + this.daywiseTransaction[index].damageAmount);
    this.calculateThisDayTotal();
  }

  calculateThisDayTotal() {
    let quantity: number = 0;
    let commission: number = 0;
    let damage: number = 0;
    let damageRep: number = 0;
    let extraBetel: number = 0;
    let totalAmount: number = 0;
    this.daywiseTransaction.forEach(item => {
      quantity += parseFloat(item.quantity);
      commission += parseFloat(item.commissionAmount);
      damage += parseFloat(item.damage);
      damageRep += parseFloat(item.damageReputation);
      extraBetel += parseFloat(item.extraBetel);
      totalAmount += parseFloat(item.totalAmount);
    });

    this.thisDayQuantity = quantity;
    this.thisDayCommission = commission;
    this.thisDayDamage = damage;
    this.thisDayDamageRep = damageRep;
    this.thisDayExtraBetel = extraBetel;
    this.thisDayTotalAmount = totalAmount;
  }

}
