import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [MessageService]
})
export class ForgotPasswordComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl('')
  })

  showError = {
    flag: false,
    message: ""
  }

  loading: boolean = false;

  constructor(private loginService: LoginService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    let payload = this.loginForm.value;
    this.showError = {
      flag: false,
      message: ""
    }
    this.loading = true;
    this.loginService.getUser(payload).subscribe(res => {
      
      if (res.success === true) {
        if (res.data.email) {
          let payload = { "email": res.data.email, "link" : window.location.protocol + "//" + window.location.host + "/update-password", "token": res.token };
          this.loginService.sendEmail(payload).subscribe(res => {
            
            this.loading = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Mail sent to your register Email Address' });
          }, error => {
            this.loading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Mail not sent!!' });
          })
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Email Address not found' });
        }
      }
      else if (res.success === false) {
        this.loading = false;
        this.showError.flag = true;
        this.showError.message = res.message;
      }
    })
  }

}
